var AddressFind = (function() {
    // S : BTOCSITE-25299
    var AddressFindTemplate = '<article id="findAddressLayer" class="popup-wrap find-address-layer">'
    + '<header class="pop-header"><h1 class="tit"><span>주소 찾기</span></h1></header>'
    + '<section class="pop-conts common-pop">'
    + '<div id="postLayer"></div>'
    + '</section>'
    + '<button type="button" class="btn-close ui_modal_close"><span class="blind">닫기</span></button>'
    + '</article>';
    // E : BTOCSITE-25299
    function AddressFind(completeCallback) {
        var self = this;
        self.completeCallback = completeCallback;

        self.isPostCode = false;
        self._loadDaumPostApiJs(function() {
            self.isPostCode = true;
        });
    }

    //public
    AddressFind.prototype = {
        // S : BTOCSITE-25299
        modal: function(completeCallback) {
            var self = this;
            if(completeCallback) {
                self.completeCallback = completeCallback;
            }
            if(self.isPostCode){
                var $popupWrap = $('#findAddressLayer');
                if($popupWrap.size() == 0) {
                    $('body').append(AddressFindTemplate);
                    $popupWrap = $('#findAddressLayer');
                    $popupWrap
                    .on('modalhidden', function(e) {
                        self._loadDaumPostApiJs(function() {
                            $('#postLayer').empty();
                            self.daumPost = new daum.Postcode(self.daumPost._opt_)
                        });
                        self.isLayerpopup = null;
                    });
                }
                self.isLayerpopup = function() {
                    $popupWrap.vcModal('close');
                };
                $popupWrap.vcModal();
                var height = ($(window).width()<678) ? $(window).height() - $popupWrap.find('.pop-header').height() : 500;
                self.daumPost._opt_.width = '100%';
                self.daumPost._opt_.height = height;
                self.daumPost.embed(document.getElementById('postLayer'));
            }
        },
        // E : BTOCSITE-25299

        open: function(completeCallback) {
            var self = this;
            if(completeCallback) {
                self.completeCallback = completeCallback;
            }
            if(self.isPostCode){
                self.daumPost.open();
            }
        },

        close: function() {
            var self = this;
            self.daumPost.close();
        },

        _importDaumPostApiJs: function() {
            var defer = $.Deferred();
            var script = document.createElement('script');
    
            script.onload = function () {
                defer.resolve();
            };
            script.onerror = function(e){ 
                defer.reject('map api를 로드할수 없습니다.');          
            }
            script.src = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";        
            document.head.appendChild(script);  
    
            return defer.promise();
        },
        
        _loadDaumPostApiJs: function(callback){
            if(window.daum && window.daum.Postcode){
                if(callback) callback();
            } else {
                var self = this;
                self._importDaumPostApiJs().done(function(){
                    if(callback) callback();
                    self.daumPost = new daum.Postcode({
                        oncomplete: function(data){
                            var extraRoadAddr = ''; // 참고 항목 변수
                            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                                extraRoadAddr += data.bname;
                            }
                            // 건물명이 있고, 공동주택일 경우 추가한다.
                            if(data.buildingName !== '' && data.apartment === 'Y'){
                            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                            }
                            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                            if(extraRoadAddr !== ''){
                                extraRoadAddr = '(' + extraRoadAddr + ')';
                            }
                            data.roadAddress = data.roadAddress + " " + extraRoadAddr;
                            if(self.completeCallback) self.completeCallback(data);
                            if(self.isLayerpopup) self.isLayerpopup(data); // BTOCSITE-25299
                        }
                    });
                }).fail(function(e){
                    alert(e);
                }) 
            } 
        }
    }

    return AddressFind;
})();

var addressValidation; // BTOCSITE-39521 ADD

var AddressRegist = (function() {
    function AddressRegist(targetQuery, completeCallback) {
        var self = this;
        self.completeCallback = completeCallback;

        self.$content = $(targetQuery);
        self.$formWrap = self.$content.find('div.form-wrap');

        //주소찾기 버튼
        self.$addressFindButton = self.$content.find('section div.form-wrap dl.forms dd.conts div.box div.cell button');

        //주소 입력 완료 버튼
        self.$submitButton = self.$content.find('footer.pop-footer div.btn-group button');

        self.addressFind = new AddressFind(function(data){
            self._updateFromDaumPostData(data);
        });

        //청약신청에서 인풋 키값 예외처리...
        var register;
        if(self.$content.data("exception")){
            register = {
        		addressNickName:{
        			// BTOCSITE-18043 START
        			// BTOCSITE-39521 START
                    /*required: true,
                    errorMsg: "주소별칭을 입력해주세요.",
                    msgTarget: '.err-block',
                    validate : function(value){
                        if( value.replace(/\s|　/gi, '') == 0) {
                            return false;
                        } else {
                            return true;
                        }
                    }*/
        			required: true,
                    errorMsg: "배송지 명을 입력해주세요.",
                    msgTarget: ".err-block",
                    validate: function (value) {
                        if (value.replace(/\s|　/gi, "") == 0) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                    // BTOCSITE-39521 END
            		// BTOCSITE-18043 END
                },
                receiverUser:{
                	/*required: true,
                    // BTOCSITE-5938-396
                    minLength: 1,
                    maxLength: 30,
                    errorMsg: "이름을 입력해주세요.",
                    msgTarget: '.err-block',
                    pattern: /^[가-힣a-zA-Z0-9\s]+$/,
                    // BTOCSITE-5938-396 s
                    validate : function(value){
                        var valTest = new RegExp(this.pattern);
                        if( value.replace(/\s|　/gi, '') == 0) {
                            this.errorMsg = "이름을 입력해주세요.";
                            return false;
                        } else if(value.length > this.maxLength) {
                            this.errorMsg = "최대 "+this.maxLength+"자까지 입력 가능합니다.";
                            return false;
                        }else if(!valTest.test(value)){
                            //BTOCSITE-5938-79 - 특수문자 validation 추가
                            this.errorMsg = "이름은 한글 또는 영문으로만 입력해주세요.";
                            return false;
                        } else {
                            return true;
                        }
                    }*/
                	required: true,
                    // BTOCSITE-5938-396
                    minLength: 1,
                    maxLength: 30,
                    errorMsg: "받는 분을 입력해주세요.", 
                    msgTarget: ".err-block",
                    pattern: /^[가-힣a-zA-Z0-9\s]+$/,
                    // BTOCSITE-5938-396 s
                    validate: function (value) {
                        var valTest = new RegExp(this.pattern);
                        if (value.replace(/\s|　/gi, "") == 0) {
                            this.errorMsg = "받는 분을 입력해주세요.";
                            return false;
                        } else if (value.length > this.maxLength) {
                            this.errorMsg = "최대 " + this.maxLength + "자까지 입력 가능합니다.";
                            return false;
                        } else if (!valTest.test(value)) {
                            //BTOCSITE-5938-79 - 특수문자 validation 추가
                            this.errorMsg = "이름은 한글 또는 영문으로만 입력해주세요.";
                            return false;
                        } else {
                            return true;
                        }
                    },
                    // BTOCSITE-5938-396 e
                    // BTOCSITE-5938-396 e
                },
                zipCode:{
                    required: true,
                    pattern: /^[0-9]+$/,
                    errorMsg: "우편번호를 확인해주세요.",
                    msgTarget: '.err-block'
                },
                userAddress:{
                    required: true,
                    errorMsg: "주소를 확인해주세요.",
                    msgTarget: '.err-block'
                },
                detailAddress: {
                	// BTOCSITE-18043 START
                    required: true,
                    errorMsg: "상세주소를 입력해주세요.",
                    msgTarget: '.err-block',
                    pattern: /^[가-힣a-zA-Z0-9ㆍᆢ@(),-\s]+$/,
                    // BTOCSITE-5938-396 s
                    validate : function(value){
                        var valTest = new RegExp(this.pattern);
                        if( value.replace(/\s|　/gi, '') == 0) {
                            this.msgTarget = '.err-block';
                            this.errorMsg = "상세주소를 입력해주세요.";
                            return false;
                        } else if(!valTest.test(value)){
                            //BTOCSITE-5938-79 - 특수문자 validation 추가
                            this.msgTarget = '.err-block';
                            this.errorMsg = "@ ( ) - , 를 제외한 특수문자는 입력하실 수 없습니다.";
                            return false;
                        } else {
                            return true;
                        }
                    }
                    // BTOCSITE-5938-396 e
                	// BTOCSITE-18043 END
                },
                mobilePhone:{
                    required: true,
                    pattern: /^[0-9]+$/
                },
                phoneNumber:{
                    required: true,
                    minLength:10,
                    errorMsg: "휴대폰번호를 입력해주세요.",
                    msgTarget: '.err-block'
                },
                telephoneNumber:{
                	pattern: /[0-9]{10,11}$/,
                    required: false
                },
            }
        } else {
            register = {
                addressName:{
                    required: true,
                    errorMsg: "주소별칭을 입력해주세요.",
                    msgTarget: '.err-block'
                },
                name:{
                    required: true,
                    errorMsg: "이름을 입력해주세요.",
                    msgTarget: '.err-block'
                },
                zipCode:{
                    required: true,
                    pattern: /^[0-9]+$/,
                    errorMsg: "우편번호를 확인해주세요.",
                    msgTarget: '.err-block'
                },
                userAddress:{
                    required: true,
                    errorMsg: "주소를 확인해주세요.",
                    msgTarget: '.err-block'
                },
                detailAddress: {
                	// BTOCSITE-18043 START
                    required: true,
                    errorMsg: "상세주소를 입력해주세요.",
                    msgTarget: '.err-block',
                    pattern: /^[가-힣a-zA-Z0-9ㆍᆢ@(),-\s]+$/,
                    // BTOCSITE-5938-396 s
                    validate : function(value){
                        var valTest = new RegExp(this.pattern);
                        if( value.replace(/\s|　/gi, '') == 0) {
                            this.msgTarget = '.err-block';
                            this.errorMsg = "상세주소를 입력해주세요.";
                            return false;
                        } else if(!valTest.test(value)){
                            //BTOCSITE-5938-79 - 특수문자 validation 추가
                            this.msgTarget = '.err-block';
                            this.errorMsg = "@ ( ) - , 를 제외한 특수문자는 입력하실 수 없습니다.";
                            return false;
                        } else {
                            return true;
                        }
                    }
                    // BTOCSITE-5938-396 e
                	// BTOCSITE-18043 END
                },
                mobilePhone:{
                    required: true,
                    pattern: /^[0-9]+$/,
                    minLength:10,
                    errorMsg: "휴대폰번호를 입력해주세요.",
                    msgTarget: '.err-block'
                },
                phone:{
                    required: false,
                    pattern: /^[0-9]+$/,
                    minLength:10,
                    errorMsg: "휴대폰번호를 입력해주세요.",
                    msgTarget: '.err-block'
                }
            }
        }


        vcui.require(['ui/pagination','ui/validation'], function () {
            self._bindEvents();

            self.validation = new vcui.ui.Validation('#address-regist-form',{register:register});
            addressValidation = self.validation; // BTOCSITE-39521 ADD
            /* 
            self.validation.on('update', function(e){ 
                console.log('update');
            }).on('errors', function(e,data){
                console.log('errors', data);
            }).on('success', function(data){
                console.log('success');
            });
            */
        });
    }

    //public
    AddressRegist.prototype = {
        open: function(opener, data) {
            var self = this;
            self._resetData(data);
            self.$content.vcModal({ opener: opener });
        },

        close: function() {
            var self = this;
            self.$content.vcModal('close');
        },

        _bindEvents: function() {
            var self = this;
            
            //주소 찾기 버튼
            self.$addressFindButton.on('click', function(e) {
                self.addressFind.modal();
            })

            self.$submitButton.on('click', function(e) {
                var result = self.validation.validate();
                if(result.success){
                    self._postAddressData(self.validation.getAllValues());
                }
            })
            
            // BTOCSITE-39521 START
            /* Emoji 입력 방지 */
            $(document).on("input", 'input[type="text"]', function () {
                var $this = $(this),
                    value = $this.val(),
                    regex =
                        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
                if (regex.test(value)) {
                    $this.val(value.replace(regex, ""));
                    return;
                }
            });
            
            /* number Type일 경우 마우스 휠 증감 방지 */
            $('input[type="number"]').attr("inputmode", "numeric");
            $("input[type=number]")
                .on("keydown", function (e) {
                    return e.keyCode !== 69;
                })
                .on("mousewheel", function (e) {
                    if ($(this).attr("name") == "phoneNumber" || $(this).attr("name") == "telephoneNumber") return false;
                });
            // BTOCSITE-39521 END 
        },

        _updateFromDaumPostData: function(data) {
            //console.log('daumPost complete',data);
            // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            
            var roadAddr = data.roadAddress; // 도로명 주소 변수
            var extraRoadAddr = ''; // 참고 항목 변수
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
			// BTOCSITE-16161 [QA요청] 주소입력 레이어 팝업 주소 표시 오류 START
            // data.roadAddress에 '(동이름)'이 있기때문에 아래 로직 주석처리. 
            // if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
            //    extraRoadAddr += data.bname;
            // }
            // BTOCSITE-16161 [QA요청] 주소입력 레이어 팝업 주소 표시 오류 END
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = '(' + extraRoadAddr + ')';
            }
            
            var self = this;
            self.$formWrap.find('input[name=zipCode]').val(data.zonecode);
            self.$formWrap.find('input[name=userAddress]').val(roadAddr);//BTOCSITE-17733
            self.$formWrap.find('input[name=detailAddress]').val('');
        },

        _postAddressData: function(data) {
            var self = this;
            var addressID = self.$content.attr('data-address-id');
            var ajaxUrl = self.$content.attr('data-insert-url');
            if(addressID) {
                //신규가 아닌 수정
                data.addressID = addressID;
                ajaxUrl = self.$content.attr('data-update-url');
            }
            lgkorUI.requestAjaxDataPost(ajaxUrl, data, function(result){
                self.completeCallback(data);
                self.close();
            });
        },

        _resetData:function(data) {
            var self = this;
            if(data) {
                self.$content.attr('data-address-id',data.addressID);
                $.each(data, function(key, value) {
                    var $input = self.$formWrap.find('input[name=' + key + ']');
                    if($input.is(':checkbox')) {
                        $input.prop("checked", value);
                    } else {
                        $input.val(value);
                    }
                });
            } else {
                self.$content.removeAttr('data-address-id');
                self.$formWrap.find('input').val('');
            }
        }
    }

    return AddressRegist;
})();

var AddressManagement = (function() {
    var addressItemTemplate = '<li class="lists" data-id="{{addressID}}" data-address="{{jsonData}}"><div class="inner">' +
        '<dl class="addr">' +
            '<dt>' +
                '<p><span class="blind">배송지명</span>{{addressName}}</p>' +
                '{{#if defaultAddress}}<span class="flag-wrap"><span class="flag">기본배송지</span></span>{{/if}}' +
            '</dt>' +
            '<dd><p><span class="blind">이름</span>{{name}}</p><p><span class="blind">연락처</span>{{phoneString}}</p></dd>' +
            '<dd><p><span class="blind">주소</span>{{userAddress}} {{detailAddress}}</p></dd>' +
        '</dl>' +
        '<div class="btns">' +
            '<button class="btn border size edit"><span>수정</span></button>' +
            '{{#if !(defaultAddress)}}<button class="btn border size remove"><span>삭제</span></button>{{/if}}' +
        '</div>' +
        '<span class="rdo-wrap">' +
            '<input type="radio" id="address-rdo1-{{index}}" name="address-rdo1" {{#if defaultAddress}}checked{{/if}}>' +
            '<label for="address-rdo1-{{index}}">배송지 선택</label>' +
        '</span>' +
    '</div></li>'

    function AddressManagement(targetQuery, registerTargetQuert, selectCallback) {
        var self = this;
        self.selectCallback = selectCallback;
        
        self.$content = $(targetQuery);

        //배송지 리스트
        self.$addressLists = self.$content.find('ul.address-lists');
        //페이지목록
        self.$pagination = self.$content.find('div.pagination');
        //데이타없음
        self.$noData = self.$content.find('div.no-data');
        //하단
        self.$footer = self.$content.find('.pop-footer');
        //배송지선택 버튼
        self.$selectAddress = self.$footer.find('div.btn-group button');
        //배송지추가 버튼
        self.$addAddress = self.$content.find('div.my-address-wrap button.btn-addr');

        self.addressRegist = new AddressRegist(registerTargetQuert, function(data){
            var param = {page:self.$pagination.attr("data-page")};
            self._getData(param);
        });

        vcui.require(['ui/pagination'], function () {
            self._bindEvents();
        });

        self._checkNoData();
    }

    //public
    AddressManagement.prototype = {
        open: function(selectCallback) {
            var self = this;
            if(selectCallback) {
                self.selectCallback = selectCallback;
            }
            var param = {"page": "0"}
            self._getData(param, function(){
                self.$content.vcModal();
            });
        },

        close: function() {
            var self = this;
            self.$content.vcModal('close');
        },

        _getData: function(param, complete) {
            var self = this;
            var ajaxUrl = self.$content.attr('data-list-url');
            lgkorUI.requestAjaxData(ajaxUrl, param, function(result){
                self._updateList(result);
                if(complete) {
                    complete();
                }
            });
        },

        _updateList: function(data) {
            var self = this;

            self.$pagination.vcPagination('setPageInfo', data.param.pagination);
            
            var itemList =  data.data instanceof Array ? data.data : [];

            self.$addressLists.empty();
            if(itemList.length > 0) {
                itemList.forEach(function(item, index) {
                    item.index = index;
                    item.phoneString = item.mobilePhone ? vcui.number.phoneNumber(item.mobilePhone) : (item.phone ? vcui.number.phoneNumber(item.phone) : '');

                    item.addressNickName = item.addressName;
                    item.receiverUser = item.name;
                    item.phoneNumber = item.mobilePhone;
                    item.telephoneNumber = item.phone;

                    item.jsonData = self._encodeAddressData(item);
                    self.$addressLists.append(vcui.template(addressItemTemplate, item));
                });
            }

            self._checkNoData();
        },

        _bindEvents: function() {
            var self = this;
            
            self.$pagination.vcPagination({scrollTop : 'noUse', }); // BTOCSITE-39521 ADD
            
            //페이지
            self.$pagination.vcPagination().on('page_click', function(e, data) {
                //기존에 입력된 데이타와 변경된 페이지로 검색
                var param = {"page":data}
                self._getData(param);
            });
            
            //배송지 추가
            self.$addAddress.on('click', function(e) {
            	e.preventDefault(); // BTOCSITE-39521 ADD
            	
            	 //BTOCSITE-28281 배송지 관리 타이틀 수정 start
                if($("#delAddrTitle").length > 0){
                	$("#delAddrTitle").text("주소 추가")
                }
                
                if($("#delAddrSendBtn").length > 0){
                	$("#delAddrSendBtn").text("확인")
                }
                //BTOCSITE-28281 배송지 관리 타이틀 수정 end
                
                $("#popup-delivery-address").find(".err-block").hide();
                $("#popup-delivery-address").find(".err-block").hide().find('.err-msg').text("");
                
                $("#popup-delivery-address input[name=defaultAddress]").prop("checked", false); // 주소 추가시 기본배송지 설정 checkbox 해제 BTOCSITE-39521 ADD 
                
                addressValidation.validItemObj = {}; // 레이어 팝업일때 validation 초기화 BTOCSITE-39521 ADD 
                
                self.addressRegist.open($(this));
            })

            //배송지 선택
            self.$selectAddress.on('click', function(e){
                var $item = self.$addressLists.find('li.lists div.inner span.rdo-wrap input:checked');
                var addressData = self._decodeAddressData($item.parents('li.lists').attr('data-address'));
                if(self.selectCallback) {
                    self.selectCallback(addressData);
                }
                self.close();
            });

            //아이템 수정
            self.$addressLists.on('click', 'li.lists div.inner div.btns button', function(e){
                var $this = $(this);
                var addressID = $this.parents('li.lists').attr('data-id');
                if($this.hasClass('edit')) {
                    //아이템 수정
                    var addressData = self._decodeAddressData($this.parents('li.lists').attr('data-address'));
                    
                    //BTOCSITE-28281 배송지 관리 타이틀 수정 start
                    if($("#delAddrTitle").length > 0){
                    	$("#delAddrTitle").text("주소 수정")
                    }
                    
                    if($("#delAddrSendBtn").length > 0){
                    	$("#delAddrSendBtn").text("주소 저장")
                    }
                    //BTOCSITE-28281 배송지 관리 타이틀 수정 end
                    
                    $("#popup-delivery-address").find(".err-block").hide();
                    $("#popup-delivery-address").find(".err-block").hide().find('.err-msg').text("");
                    
                    addressValidation.validItemObj = {};
                    
                    self.addressRegist.open($(this), addressData);
                    
                } else if($this.hasClass('remove')){
                    //아이템 삭제
                	// BTOCSITE-39521 START
                	lgkorUI.confirm("선택한 배송지를 삭제 하시겠습니까?", {
                        title: "",
                        cancelBtnName: "취소",
                        okBtnName: "확인",
                        cancel: function () { return; },
                        ok: function () {
                        	var ajaxUrl = self.$content.attr('data-remove-url');
                            var param = {addressID:addressID, page:self.$pagination.attr("data-page")};
                            lgkorUI.requestAjaxData(ajaxUrl, param, function(result){
                                self._updateList(result);
                                if (result.status === "success") {
                                	$(window).trigger("toastshow", "선택하신 주소가 삭제 되었습니다.");
                                }
                            });
                        },
                    });
                	// BTOCSITE-39521 END
                }
            });
        },

        _checkNoData: function() {
            var self = this;
            self._noData(self.$addressLists.find('li.lists').length > 0 ? false : true);
        },

        _noData: function(visible) {
            var self = this;
            if(visible) {
                self.$noData.show();
                self.$addressLists.hide();
                self.$pagination.hide();
                self.$footer.hide();
            } else {
                self.$noData.hide();
                self.$addressLists.show();
                self.$pagination.show();
                self.$footer.show();
            }
        },

        _encodeAddressData: function(item) {
            return encodeURIComponent(JSON.stringify(item));
        },

        _decodeAddressData: function(data) {
            return JSON.parse(decodeURIComponent(data));
        }
    }

    return AddressManagement;
})();